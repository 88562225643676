.md-main {
  min-height: 90vh;

  .md-content {
    .md-typeset {
      h3 + p {
        margin-top: -10px;
      }
    }
  }
}